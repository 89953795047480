import { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import './checkbox.css';
import './App.css';
import { Auth } from './components/auth';
import MqttComponent from './components/mqttComponent';
import { HandleIssue } from './components/handleIssue';
import { IssuesTable } from './components/issuesTable';
import { IssueSettings } from './components/issueSettings';
import { UnitActivityChart } from './components/unitActivityChart';
import { PieChart } from './components/pieChart';
import { BarChart } from './components/barChart';
import { StateCounter } from './components/stateCounter';
import { auth4, db4, database } from './firebase';
import { ref, get, onValue, set, off } from "firebase/database";
import { signOut, setPersistence, browserSessionPersistence } from 'firebase/auth';
import axios from 'axios';
import cors from 'cors';
import {
  getDoc,
  getDocs,
  collection,
  setDoc,
  doc,
  onSnapshot,
  deleteDoc,
  writeBatch,
  serverTimestamp,
  or,
  query,
  where,
} from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as KamleonLogo } from './img/kamleon_logo.svg';
import { ReactComponent as LogOut } from './img/logout.svg';
import { ReactComponent as GearIcon } from './img/gear.svg';
import { ReactComponent as Critical } from './img/critical.svg';
import { ReactComponent as Error } from './img/error.svg';
import { ReactComponent as Warning } from './img/warning.svg';
import { ReactComponent as Exception } from './img/exception.svg';

function App() {
  const [user, setUser] = useState(() => JSON.parse(sessionStorage.getItem('user')) || null);
  var userName = sessionStorage.getItem('userName');
  var userLastName = sessionStorage.getItem('userLastName');
  var userRole = sessionStorage.getItem('userRole');
  const [email, setEmail] = useState(sessionStorage.getItem('userEmail') || '');
  const [dashboardUnitsComplete, setDashboardUnitsComplete] = useState({});
  const [dashboardUnitsUpdated, setDashboardUnitsUpdated] = useState({});
  const [groupedDataByUnit, setGroupedDataByUnit] = useState({});
  const [data, setData] = useState([]);
  const [criticalIssuesData, setCriticalIssuesData] = useState(new Set());
  const [usersData, setUsersData] = useState([]);
  const [organizationsData, setOrganizationsData] = useState([]);
  const [centersData, setCentersData] = useState([]);
  const [devicesData, setDevicesData] = useState([]);
  const [unitsData, setUnitsData] = useState([]);
  const [dashboardUnitsData, setDashboardUnitsData] = useState([]);
  const [groupIssues, setGroupIssues] = useState(true);
  const [issueGroupToModify, setIssueGroupToModify] = useState({});
  const [issueIdFilter, setIssueIdFilter] = useState('');
  const [issueTypeInDashboardFilter, setIssueTypeInDashboardFilter] = useState('');
  const [issueTypeFilter, setIssueTypeFilter] = useState('');
  const [centerFilter, setCenterFilter] = useState('');
  const [organizationFilter, setOrganizationFilter] = useState('');
  const [unitIdFilter, setUnitIdFilter] = useState('');
  const [unitChart, setUnitChart] = useState([
    { type: 'Active', amount: 0, color: '#2FD5CB' },
    { type: 'Disabled', amount: 0, color: '#FC664E' },
  ]);
  const [pieChartData, setPieChartData] = useState([
    { type: 'Critical', amount: 0, color: '#000000' },
    { type: 'Error', amount: 0, color: '#FC664E' },
    { type: 'Warning', amount: 0, color: '#FAC63E' },
    { type: 'Exception', amount: 0, color: '#808285' },
  ]);
  const [barChartData, setBarChartData] = useState([
    { type: 'Cartridge', amount: 0, color: '#2C2C2C' },
    { type: 'Screen', amount: 0, color: '#757575' },
    { type: 'Unit', amount: 0, color: '#b8b8b8' },
  ]);
  const [stateCounteData, setStateCounterData] = useState([
    { type: 'Open', amount: 0, color: '#FC664E' },
    { type: 'In progress', amount: 0, color: '#FFD952' },
    { type: 'Closed', amount: 0, color: '#2FD5CB' },
  ]);
  const [showApp, setShowApp] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showIssueSettings, setShowIssueSettings] = useState(false);
  const [showDashboard, setShowDashboard] = useState(true);
  const [showIssuesTable, setShowIssuesTable] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedUnitCenter, setSelectedUnitCenter] = useState(null);
  const [currentIssuesList, setCurrentIssuesList] = useState([]);
  const [showMqttComponent, setShowMqttComponent] = useState(false);
  const [showMqttModal, setShowMqttModal] = useState(false);
  const [showHandleIssue, setShowHandleIssue] = useState(false);
  const [issueToModify, setIssueToModify] = useState(null);
  const [sortField, setSortField] = useState('unit_status');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortFieldIssue, setSortFieldIssue] = useState('timestamps.open.Last_open');
  const [sortOrderIssue, setSortOrderIssue] = useState('desc');
  const [issueIDs, setIssueIDs] = useState([{ value: '', label: 'Any issue hidden' }]);
  const [issueIDsSelected, setIssueIDsSelected] = useState([]);
  const [issueStatusSelected, setIssueStatusSelected] = useState([
    { value: "Open", label: "Open" },
    { value: "In progress", label: "In progress" },
  ]);

  const issueStatus = [
    { value: "Open", label: "Open" },
    { value: "In progress", label: "In progress" },
    { value: "Closed", label: "Closed" },
  ];

  const critical_issues = collection(db4, 'critical_issues');
  const devices = collection(db4, 'devices');
  const units = collection(db4, 'units');
  const organizations = collection(db4, 'organizations');
  const centers = collection(db4, 'centers');
  const monitoring_dashboard = collection(db4, 'monitoring_dashboard');
  const kam_eye_issues = collection(db4, 'kam_eye');
  const users = collection(db4, 'users');
  const issue_types = collection(db4, 'monitoring_issue_types');

  // Ref to track initial load
  const isInitialLoadIssues = useRef(true);
  const isInitialLoadUnits = useRef(true);
  const isInitialLoadDevices = useRef(true);
  const criticalIssuesDataRef = useRef(criticalIssuesData);
  const dashboardUnitsDataRef = useRef(dashboardUnitsData);

  const navigate = useNavigate();


  const updateUnitChartData = (filteredData) => {
    const activeUnits = filteredData.filter(item => item.status === 'active').length;
    const disabledUnits = filteredData.filter(item => item.status === 'disabled').length;

    setUnitChart([
      { type: 'Active', amount: activeUnits, color: '#2FD5CB' },
      { type: 'Disabled', amount: disabledUnits, color: '#FC664E' },
    ]);
  };

  const updatePieChartData = (filteredData) => {
    const issueTypeCounts = {};
    filteredData
      .filter(item => item.Issue.status === 'Open' || item.Issue.status === 'In progress')
      .forEach(item => {
        const issueType = item.Issue.type;
        issueTypeCounts[issueType] = (issueTypeCounts[issueType] || 0) + 1;
      });
    const newPieChartData = pieChartData.map(data => ({
      ...data,
      amount: issueTypeCounts[data.type] || 0,
    }));

    setPieChartData(newPieChartData);
  };

  const updateBarChartData = (filteredData) => {
    const deviceTypeCounts = {};
    filteredData
      .filter(item => item.Issue.status === 'Open' || item.Issue.status === 'In progress')
      .forEach(item => {
        const deviceType = item.Installation.Dev_type;
        deviceTypeCounts[deviceType] = (deviceTypeCounts[deviceType] || 0) + 1;
      });
    const newBarChartData = barChartData.map(data => ({
      ...data,
      amount: deviceTypeCounts[data.type] || 0,
    }));

    setBarChartData(newBarChartData);
  };

  const updateStateCounterData = (filteredData) => {
    const stateCounts = {};
    filteredData.forEach(item => {
      const state = item.Issue.status;
      stateCounts[state] = (stateCounts[state] || 0) + 1;
    });
    const newStateCounterData = stateCounteData.map(data => ({
      ...data,
      amount: stateCounts[data.type] || 0,
    }));

    setStateCounterData(newStateCounterData);
  };

  const handleSort = (field) => {
    // If clicking on the same field, reverse the sort order
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortField(field);
      setSortOrder('asc');
    }
  };

  const handleSortIssue = (field) => {
    // If clicking on the same field, reverse the sort order
    if (field === sortFieldIssue) {
      setSortOrderIssue(sortOrderIssue === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking on a different field, set the new field and default to ascending order
      setSortFieldIssue(field);
      setSortOrderIssue('asc');
    }
  };

  // async function deleteDocumentss(issueGroup) {
  //   const batch = writeBatch(db3);
  //   const cutoffDate = new Date('2024-05-20T00:00:00Z');
  //   var count = 0;
  //   for (const issue of issueGroup) {
  //     const createdAt = issue.timestamps?.open?.Created_at?.toDate();  // Assuming Created_at is a Firestore Timestamp
  //     if (createdAt && createdAt < cutoffDate && issue.Installation.ID === "Rhampholeon") {
  //       const docRef = doc(db3, 'kam_eye', issue.id);
  //       await deleteDoc(docRef);
  //       count++;
  //       console.log('Document deleted:', issue.id, 'Count:', count);
  //     }
  //   }
  // }

  const fetchIssues = async () => {
    try {
      // Step 1: Fetch data from the critical_issues collection
      const criticalIssuesSnapshot = await getDocs(critical_issues);
      const criticalIssueIds = new Set(criticalIssuesSnapshot.docs.map(doc => doc.data().issueId.toLowerCase()));
      setCriticalIssuesData(criticalIssueIds);
  
      // Step 2: Fetch data from kam_eye_issues collection
      const querySnapshot = await getDocs(kam_eye_issues);
      const currentDate = new Date();
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      // Step 3: Filter and transform the data
      const filteredData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const issueId = data.Issue?.ID?.toLowerCase();
  
        if (issueId && criticalIssueIds.has(issueId)) {
          data.Issue.type = "Critical";
        }
  
        return { ...data, id: doc.id };
      });
  
      // Step 4: Update the state and charts
      setData(filteredData);
      updatePieChartData(filteredData);
      updateBarChartData(filteredData);

      // Step 5: Filter data for the last seven days and update state counter data
      const recentData = filteredData.filter((item) => {
        const openTimestamps = item.timestamps?.open;

        if (openTimestamps) {
          const latestTimestamp = Object.values(openTimestamps)
            .map(timestamp => timestamp?.toDate())
            .sort((a, b) => b - a)[0];
          return latestTimestamp && latestTimestamp >= sevenDaysAgo;
        }
        return false;
      });
      updateStateCounterData(recentData);
    } catch (error) {
      console.error('Error fetching kam_eye data:', error);
    }
  }

  const fetchMonitoringDashboard = async () => {
    try {
      // Fetch data from monitoring_dashboard collection
      const dashboardSnapshot = await getDocs(monitoring_dashboard);
      const dashboardUnitsData = dashboardSnapshot.docs.map(doc => doc.data());
      setDashboardUnitsData(dashboardUnitsData);
    } catch (error) {
      console.error('Error fetching monitoring dashboard data:', error);
    }
  }

  const fetchData = async () => {
    try {
      const criticalIssuesPromise = getDocs(critical_issues);
      const kamEyeIssuesPromise = getDocs(
        query(kam_eye_issues, where("Issue.status", "in", ["Open", "In progress"]))
      );
      const devicesPromise = getDocs(devices);
      const dashboardPromise = getDocs(monitoring_dashboard);
      const unitsPromise = getDocs(units);
      const organizationsPromise = getDocs(organizations);
      const centersPromise = getDocs(centers);

      const [
        criticalIssuesSnapshot,
        kamEyeIssuesSnapshot,
        devicesSnapshot,
        dashboardSnapshot,
        unitsSnapshot,
        organizationsSnapshot,
        centersSnapshot
      ] = await Promise.all([
        criticalIssuesPromise,
        kamEyeIssuesPromise,
        devicesPromise,
        dashboardPromise,
        unitsPromise,
        organizationsPromise,
        centersPromise
      ]);

      // Step 1: Process critical_issues collection data
      const criticalIssueIds = new Set(criticalIssuesSnapshot.docs.map(doc => doc.data().issueId.toLowerCase()));
      setCriticalIssuesData(criticalIssueIds);
  
      // Step 2: Process kam_eye_issues collection data
      const currentDate = new Date();
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);


      const filteredData = kamEyeIssuesSnapshot.docs.map((doc) => {
        const data = doc.data();
        const issueId = data.Issue?.ID?.toLowerCase();
  
        if (issueId && criticalIssueIds.has(issueId)) {
          data.Issue.type = "Critical";
        }
  
        return { ...data, id: doc.id };
      });
  
      // Step 3: Update the state and charts
      setData(filteredData);
      updatePieChartData(filteredData);
      updateBarChartData(filteredData);

      // Step 4: Filter data for the last seven days and update state counter data
      const recentData = filteredData.filter((item) => {
        const openTimestamps = item.timestamps?.open;

        if (openTimestamps) {
          const latestTimestamp = Object.values(openTimestamps)
            .map(timestamp => timestamp?.toDate())
            .sort((a, b) => b - a)[0];
          return latestTimestamp && latestTimestamp >= sevenDaysAgo;
        }
        return false;
      });
      updateStateCounterData(recentData);

      const devicesData = devicesSnapshot.docs.map(doc => doc.data());
      setDevicesData(devicesData);

      const dashboardUnitsData = dashboardSnapshot.docs.map(doc => doc.data());
      setDashboardUnitsData(dashboardUnitsData);

      const unitsData = unitsSnapshot.docs.map(doc => doc.data());
      setUnitsData(unitsData);

      const dashboardUnitIds = dashboardUnitsData.map(unit => unit.unitId);
      const matchingUnits = unitsData.filter(unit => dashboardUnitIds.includes(unit.unitID));
      updateUnitChartData(matchingUnits);

      const organizationsData = organizationsSnapshot.docs.map(doc => doc.data());
      setOrganizationsData(organizationsData);

      const centersData = centersSnapshot.docs.map(doc => doc.data());
      setCentersData(centersData);

      const data = await fetchDashboardUnits(filteredData, devicesData, dashboardUnitsData, unitsData, organizationsData, centersData);
      const updatedData = Object.values(data).filter((item) => {
        if (unitIdFilter !== '' && !item.unit?.toString().toLowerCase().startsWith(unitIdFilter.toLowerCase())) {
          return false;
        }
        if (organizationFilter && item.organization?.toLowerCase() !== organizationFilter.toLowerCase()) {
          return false;
        }
        if (centerFilter && item.center?.toLowerCase() !== centerFilter.toLowerCase()) {
          return false;
        }
        if (issueTypeInDashboardFilter !== '' && item.status?.toLowerCase() !== issueTypeInDashboardFilter.toLowerCase()) {
          return false;
        }
        return true;
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };



  useEffect( () => {
    try {
      if (selectedUnit !== null) {
        handleCurrentIssuesList(selectedUnit);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, [data]);

  const fetchUsers = async () => {
    try {
      const userDocs = await getDocs(users);
      const usersArray = userDocs.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .filter((user) => user.kamleonPermissions?.userRole === 'kamleon_admin' || user.kamleonPermissions?.userRole === 'kamleon_viewer' || user.kamleonPermissions?.userRole === 'kamleon_editor' || user.kamleonPermissions?.userRole === 'kamleon_owner');
  
      setUsersData(usersArray);
    } catch (error) {
      console.error('Error fetching users data:', error);
    }
  };

  function addLeadingZero(number) {
    return number < 10 ? '0' + number : number;
  }

  function formatSinceLastScore(sinceLastScore) {
    if (!sinceLastScore) {
      return '-';
    }
    const inputTimestamp = new Date(sinceLastScore).getTime();
    const currentTimestamp = Date.now();
    const differenceInMs = currentTimestamp - inputTimestamp;
  
    const differenceInMinutes = Math.floor(differenceInMs / (1000 * 60));
    const differenceInHours = Math.floor(differenceInMs / (1000 * 60 * 60));
    const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
  
    if (differenceInMinutes < 60) {
      return `${differenceInMinutes}m`;
    } else if (differenceInHours < 24) {
      const hours = differenceInHours;
      const minutes = differenceInMinutes % 60;
      return `${hours}h ${minutes}m`;
    } else {
      const days = differenceInDays;
      const hours = differenceInHours % 24;
      const minutes = differenceInMinutes % 60;
      return `${days}d ${hours}h ${minutes}m`;
    }
  }

  function formatTimestamp(timestamp) {
    if (!timestamp) {
      return '-';
    }

    const { seconds, nanoseconds } = timestamp;
    const milliseconds = (seconds * 1000) + Math.floor(nanoseconds / 1e6);
    const date = new Date(milliseconds);

    const day = date.getDate();
    const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hour = addLeadingZero(date.getHours());
    const minute = addLeadingZero(date.getMinutes());
    const second = addLeadingZero(date.getSeconds());

    const formattedDateTime = `${day}/${month}/${year} ${hour}:${minute}:${second}`;

    return formattedDateTime;
  }
  
  const classifiedIssues = data.reduce((result, item) => {
    const installationKey = `${item.Installation.Center}-${item.Installation.Dev_type}-${item.Installation.ID}-${item.Installation.Organization}-${item.Installation.Unit_ID}`;
    const issueKey = `${item.Issue.ID}-${item.Issue.number}-${item.Issue.type}-${item.Issue.status}`;

    const combinedKey = `${installationKey}-${issueKey}`;
    if (!result[combinedKey]) {
      result[combinedKey] = [];
    }
    result[combinedKey].push(item);
    return result;
  }, {});

  const severityOrder = {
    "BackEnd": 4,
    "Critical": 4,
    "Error": 3,
    "Warning": 2,
    "Exception": 1
  };

  const groupedDataByUnitInit = {};

  data.forEach((item) => {
    const key = item.Installation.Unit_ID;
  
    if (item.timestamps.open === undefined) {
      console.log('No open timestamp:', item);
    }
  
    if (!(key in groupedDataByUnitInit)) {
      // If the unit key doesn't exist, add the current item
      groupedDataByUnitInit[key] = item;
    } else {
      const currentItem = groupedDataByUnitInit[key];
  
      const isCurrentItemClosed = currentItem.Issue.status === 'Closed';
      const isNewItemClosed = item.Issue.status === 'Closed';
  
      if (isCurrentItemClosed && !isNewItemClosed) {
        // Prioritize open items over closed items
        groupedDataByUnitInit[key] = item;
      } else if (!isCurrentItemClosed && !isNewItemClosed) {
        // Both items are open, compare severity
        if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
          groupedDataByUnitInit[key] = item;
        } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
          // Same severity, compare timestamps
          if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
            groupedDataByUnitInit[key] = item;
          }
        }
      } else if (isCurrentItemClosed && isNewItemClosed) {
        // Both items are closed, compare severity
        if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
          groupedDataByUnitInit[key] = item;
        } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
          // Same severity, compare timestamps
          if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
            groupedDataByUnitInit[key] = item;
          }
        }
      }
    }
  });

  const dashboardUnits = {};

  dashboardUnitsData.forEach((unit) => {
    dashboardUnits[unit.unitId] = {
      status: "",
      organization: "",
      center: "",
      unit: unit.unitId,
      unit_status: "",
      time: "",
      time_installed: "",
      since_last_score: "",
      daily_unit_use: "",
      total_kpod_use: "",
      temperature: "",
      humidity: "",
      kpod_id: "",
      usage_status: "",
    };
  });

  unitsData.forEach((unitData) => {
    const { unitID, totalUsage, devices, usage_control } = unitData;
    if (dashboardUnits[unitID]) {
      if (devices && devices.kPodID) {
        dashboardUnits[unitID].kpod_id = devices.kPodID || "";
      }
      dashboardUnits[unitID].usage_status = usage_control !== undefined ? usage_control.alert_level : ""; 
    }
  });

  for (const key in groupedDataByUnitInit) {
    if (key in dashboardUnits) {
      const item = groupedDataByUnitInit[key];
      const { Installation, Issue, timestamps } = item;
      const time = timestamps?.open?.Created_at || "";
      dashboardUnits[key] = {
        status: Issue.type || "",
        organization: Installation.Organization || "",
        center: Installation.Center || "",
        time: time,
        time_installed: "",
        since_last_score: "",
        daily_unit_use: "",
        total_kpod_use: "-",
        temperature: "",
        humidity: "",
        kpod_id: "",
        usage_status: "",
      };
    }
  }

  devicesData.forEach((deviceData) => {
    const { deviceID, totalUsage, temperature_cartridge, humidity_cartridge, installationDate } = deviceData;
    for (const key in dashboardUnits) {
      if (dashboardUnits[key].kpod_id === deviceID) {
        dashboardUnits[key].total_kpod_use = totalUsage || "-";
        dashboardUnits[key].temperature = (temperature_cartridge === NaN ? "-" : temperature_cartridge) || "-";
        dashboardUnits[key].humidity = (humidity_cartridge === NaN ? "-" : humidity_cartridge) || "-";
        dashboardUnits[key].time_installed = installationDate || "-";
      }
    }
  });

  // Function to fetch daily usage averages from Realtime Database
  const fetchDailyUsageAverages = async (unitID, year, month, day) => {
    try {
      const path = `/dailyUnitsUsageAverages/${unitID}/${year}/${month}/${day}/dailyUsage`;
      const snapshot = await ref(database, path);
      let data = "";
      return new Promise((resolve, reject) => {
        onValue(snapshot, (snapshot) => {
        data = snapshot.val();
        resolve(data);
        });
      });
    } catch (error) {
      console.error('Error fetching daily usage averages:', error);
      throw error; // Rethrow the error to handle it higher up in the call stack
    }
  };
  
  const fetchDailyUnitUsageTimeStamps = async (unitID) => {
    try {
      const path = `/dailyUnitUsageTimeStamps/${unitID}`;
      const snapshotRef = ref(database, path);
      return new Promise((resolve, reject) => {
        onValue(snapshotRef, (snapshot) => {
          const snapshotVal = snapshot.val();
          if (snapshotVal !== null && snapshotVal !== undefined) {
            const keys = Object.keys(snapshotVal);
            if (keys.length > 0) {
              const lastChildKey = keys[keys.length - 1];
              const timeStamp = snapshot.child(lastChildKey).val().timeStamp;
              resolve(timeStamp);
            } else {
              reject(new Error('No data available'));
            }
          } else {
            console.log("fetchTimestamp error info:", unitID);
          }
        }, (error) => {
          reject(error);
        });
      });
    } catch (error) {
      console.error('Error fetching daily unit usage timestamps:', error);
      throw error; // Rethrow the error to handle it higher up in the call stack
    }
  };
  

  // Fetch data for each unit in dashboardUnits for the current day
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
  const day = String(currentDate.getDate()).padStart(2, '0');

  for (const key in dashboardUnits) {
    const unitID = key;
    if (unitID !== "Rhampholeon") {
      fetchDailyUsageAverages(unitID, year, month, day)
        .then(dailyUsage => {
          dashboardUnits[unitID].daily_unit_use = dailyUsage === null ? 0 : dailyUsage;
          return fetchDailyUnitUsageTimeStamps(unitID);
        })
        .then(timeStamp => {
          dashboardUnits[unitID].since_last_score = timeStamp;
          dashboardUnits[unitID].unit_status = "active";
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    } else {
      dashboardUnits[unitID].unit_status = "active";
    }

  }

  const fetchDashboardUnits = async (
    dataInput = undefined,
    devicesDataInput = undefined,
    dashboardUnitsDataInput = undefined,
    unitsDataInput = undefined,
    organizationsDataInput = undefined,
    centersDataInput = undefined
  ) => {
    // Check if each parameter is undefined, and if so, use an empty array instead
    const dataChecked = dataInput !== undefined ? dataInput : data;
    const devicesDataChecked = devicesDataInput !== undefined ? devicesDataInput : devicesData;
    const dashboardUnitsDataChecked = dashboardUnitsDataInput !== undefined ? dashboardUnitsDataInput : dashboardUnitsData;
    const unitsDataChecked = unitsDataInput !== undefined ? unitsDataInput : unitsData;
    const organizationsDataChecked = organizationsDataInput !== undefined ? organizationsDataInput : organizationsData;
    const centersDataChecked = centersDataInput !== undefined ? centersDataInput : centersData;

    const groupedDataByUnit = {};
    if (devicesDataChecked === undefined || devicesDataChecked.length === 0) {
      return groupedDataByUnit;
    }

    // Initialize groupedDataByUnit with keys from dashboardUnitsDataChecked
    dashboardUnitsDataChecked.forEach(unit => {
      groupedDataByUnit[unit.unitId] = null;
    });

    dataChecked.forEach((item) => {
      const key = item.Installation.Unit_ID;
      
      // Skip if the key does not exist in groupedDataByUnit
      if (!(key in groupedDataByUnit)) {
        return;
      }

      if (item.timestamps.open === undefined) {
        console.log('No open timestamp:', item);
      }

      if (groupedDataByUnit[key] === null) {
        // If the unit key doesn't have any item yet, add the current item
        groupedDataByUnit[key] = item;
      } else {
        const currentItem = groupedDataByUnit[key];
    
        const isCurrentItemClosed = currentItem.Issue.status === 'Closed';
        const isNewItemClosed = item.Issue.status === 'Closed';
    
        if (isCurrentItemClosed && !isNewItemClosed) {
          // Prioritize open items over closed items
          groupedDataByUnit[key] = item;
        } else if (!isCurrentItemClosed && !isNewItemClosed) {
          // Both items are open, compare severity
          if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
            groupedDataByUnit[key] = item;
          } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
            // Same severity, compare timestamps
            if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
              groupedDataByUnit[key] = item;
            }
          }
        } else if (isCurrentItemClosed && isNewItemClosed) {
          // Both items are closed, compare severity
          if (severityOrder[item.Issue.type] > severityOrder[currentItem.Issue.type]) {
            groupedDataByUnit[key] = item;
          } else if (severityOrder[item.Issue.type] === severityOrder[currentItem.Issue.type]) {
            // Same severity, compare timestamps
            if (item.timestamps?.open?.Last_open > currentItem.timestamps?.open?.Last_open) {
              groupedDataByUnit[key] = item;
            }
          }
        }
      }
    });
  
    const dashboardUnits = {};

    dashboardUnitsDataChecked.forEach((unit) => {
      dashboardUnits[unit.unitId] = {
        ...dashboardUnits[unit.unitId], // Preserve existing data
        organization: "",
        center: "",
        unit: unit.unitId,
        unit_status: "",
        time: "",
        time_installed: "",
        since_last_score: "",
        daily_unit_use: "",
        total_kpod_use: "-",
        temperature: "",
        humidity: "",
        kpod_id: "",
        usage_status: "",
      };
    });
    
    unitsDataChecked.forEach((unitData) => {
      const { unitID, totalUsage, status, devices, usage_control  } = unitData;
      if (dashboardUnits[unitID]) {
        if (devices) {
          dashboardUnits[unitID].kpod_id = devices.kPodID || "-";
          dashboardUnits[unitID].organization = organizationsDataChecked.find(org => org.organizationID === unitData.organizationID)?.detailsName || "-";
          dashboardUnits[unitID].center = centersDataChecked.find(center => center.centerID === unitData.centerID)?.detailsName || "-";

        }
        dashboardUnits[unitID].usage_status = usage_control !== undefined ? usage_control.alert_level : "";
        dashboardUnits[unitID].unit_status = status || "";
      }
    });
    
    // Fill additional details from groupedDataByUnit
    for (const key in groupedDataByUnit) {
      if (groupedDataByUnit[key] !== null && key in dashboardUnits) {
        const item = groupedDataByUnit[key];
        if (item) {
          const { Issue, timestamps } = item;
          const time = timestamps?.open?.Created_at || "-"; 
          dashboardUnits[key] = {
            ...dashboardUnits[key], // Preserve existing data
            status: Issue.type || "",
            time: time,
          };
          if (dashboardUnits[key].organization === "" || dashboardUnits[key].center === "") {
            dashboardUnits[key] = {
              ...dashboardUnits[key], // Preserve existing data
              organization: item.Installation.Organization || "-",
              center: item.Installation.Center || "-",
            };
          }
        }
      } else {
        if (groupedDataByUnit[key] === null && key in dashboardUnits) {
          groupedDataByUnit[key] = { Issue: { status: "Closed" } };
        }
      }
    }
    
    devicesDataChecked.forEach((deviceData) => {
      const { deviceID, totalUsage, temperature_cartridge, humidity_cartridge, installationDate } = deviceData;
      for (const key in dashboardUnits) {
        if (dashboardUnits[key].kpod_id === deviceID) {
          dashboardUnits[key] = {
            ...dashboardUnits[key], // Preserve existing data
            total_kpod_use: totalUsage || "-",
            temperature: (temperature_cartridge === undefined ? "-" : temperature_cartridge) || "-",
            humidity: (humidity_cartridge === undefined ? "-" : humidity_cartridge) || "-",
            time_installed: installationDate || "-",
          };
        }
      }
    });
  
    // Function to fetch daily usage averages from Realtime Database
    const fetchDailyUsageAverages = async (unitID, year, month, day) => {
      try {
        const path = `/dailyUnitsUsageAverages/${unitID}/${year}/${month}/${day}/dailyUsage`;
        const snapshot = await ref(database, path);
        let data = "";
        return new Promise((resolve, reject) => {
          onValue(snapshot, (snapshot) => {
          data = snapshot.val();
          resolve(data);
          });
        });
      } catch (error) {
        console.error('Error fetching daily usage averages:', error);
        throw error; // Rethrow the error to handle it higher up in the call stack
      }
    };
    
    const fetchDailyUnitUsageTimeStamps = async (unitID) => {
      try {
        const path = `/dailyUnitUsageTimeStamps/${unitID}`;
        const snapshotRef = ref(database, path);
        return new Promise((resolve, reject) => {
          onValue(snapshotRef, (snapshot) => {
            const snapshotVal = snapshot.val();
            if (snapshotVal !== null && snapshotVal !== undefined) {
              const keys = Object.keys(snapshotVal);
              if (keys.length > 0) {
                const lastChildKey = keys[keys.length - 1];
                const timeStamp = snapshot.child(lastChildKey).val().timeStamp;
                resolve(timeStamp);
              } else {
                reject(new Error('No data available'));
              }
            } else {
              console.log("fetchTimestamp error info:", unitID);
            }
          }, (error) => {
            reject(error);
          });
        });
      } catch (error) {
        console.error('Error fetching daily unit usage timestamps:', error);
        throw error; // Rethrow the error to handle it higher up in the call stack
      }
    };    
  
    // Fetch data for each unit in dashboardUnits for the current day
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, so add 1
    const day = String(currentDate.getDate()).padStart(2, '0');
  
    for (const key in dashboardUnits) {
      const unitID = key;
      if (unitID !== "Rhampholeon") {
        fetchDailyUsageAverages(unitID, year, month, day)
          .then(dailyUsage => {
            dashboardUnits[unitID].daily_unit_use = dailyUsage === null ? 0 : dailyUsage;
            return fetchDailyUnitUsageTimeStamps(unitID);
          })
          .then(timeStamp => {
            dashboardUnits[unitID].since_last_score = timeStamp;
          })
          .catch(error => {
            console.error('Error fetching data:', error);
          });
        } else {
          dashboardUnits[unitID].unit_status = "active";
          dashboardUnits[unitID].daily_unit_use = 0;
          dashboardUnits[unitID].organization = "Rhampholeon";
          dashboardUnits[unitID].center = "Rhampholeon";
      }
    }
    setGroupedDataByUnit(groupedDataByUnit);
    return dashboardUnits;
  }

  
  const classifiedIssuesbyUnit = data.reduce((result, item) => {

    const combinedKey = item.Installation.Unit_ID;

    if (!result[combinedKey]) {
      result[combinedKey] = [];
    }

    result[combinedKey].push(item);

    return result;
  }, {});

  const classifiedIssuesbyUnitAndKey = data.reduce((result, item) => {
    const unitKey = item.Installation.Unit_ID;
    const installationKey = `${item.Installation.Center}-${item.Installation.Dev_type}-${item.Installation.ID}-${item.Installation.Organization}-${item.Installation.Unit_ID}`;
    const issueKey = `${item.Issue.ID}-${item.Issue.number}-${item.Issue.type}-${item.Issue.status}`;
    const combinedKey = `${installationKey}-${issueKey}`;

    if (!result[unitKey]) {
        result[unitKey] = {};
    }

    if (!result[unitKey][combinedKey]) {
        result[unitKey][combinedKey] = [];
    }

    result[unitKey][combinedKey].push(item);

    return result;
  }, {});

  const lastItemsByUnitAndIssue = {};

  // Iterate through each installation
  for (const unitKey in classifiedIssuesbyUnitAndKey) {
      const installation = classifiedIssuesbyUnitAndKey[unitKey];
      lastItemsByUnitAndIssue[unitKey] = [];

      // Iterate through each issue within the installation
      for (const issueKey in installation) {
          const issueItems = installation[issueKey];

          // Find the item with the latest timestamps.open.Created_at
          const lastItem = issueItems.reduce((last, current) => {
              return current.timestamps.open.Created_at > last.timestamps.open.Created_at ? current : last;
          });

          // Store the last item for this issue
          lastItemsByUnitAndIssue[unitKey].push(lastItem);
      }
  }
  
  const compareByCriteria = (a, b) => {
    // First criteria: unit_status ("active" first, "disabled" last)
    if (a.unit_status !== b.unit_status) {
      return a.unit_status === "active" ? -1 : 1;
    }
  
    // Check if the status of the issues is "Closed" for second and third criteria
    const aStatusClosed = groupedDataByUnit[a.unit]?.Issue?.status === "Closed";
    const bStatusClosed = groupedDataByUnit[b.unit]?.Issue?.status === "Closed";
  
    if (aStatusClosed && !bStatusClosed) {
      return 1;  // a should come after b if a is "Closed" and b is not
    }
  
    if (!aStatusClosed && bStatusClosed) {
      return -1; // a should come before b if a is not "Closed" and b is "Closed"
    }
  
    // Second criteria: status severity
    if (severityOrder[a.status] !== severityOrder[b.status]) {
      return severityOrder[b.status] - severityOrder[a.status];
    }
  
    // Third criteria: time (timestamp, higher values first)
    const timeA = a.time ? a.time.seconds * 1e9 + (a.time.nanoseconds || 0) : 0;
    const timeB = b.time ? b.time.seconds * 1e9 + (b.time.nanoseconds || 0) : 0;
    return timeB - timeA;
  };
  
  function sortDashboardUnits(dashboardUnitsUpdated) {
    const dashboardUnitsSorted = Object.values(dashboardUnitsUpdated).sort((a, b) => {
      // Default criteria: unit_status, status, time
      if (sortField === 'unit_status' || sortField === 'status' || sortField === 'time') {
        return compareByCriteria(a, b);
      }
  
      // Custom sorting based on sortField
      const compareA = getProperty(a, sortField)?.toString();
      const compareB = getProperty(b, sortField)?.toString();
  
      if (compareA === null || compareA === undefined) {
        return sortOrder === 'asc' ? 1 : -1;
      }
  
      if (compareB === null || compareB === undefined) {
        return sortOrder === 'asc' ? -1 : 1;
      }
  
      if (sortOrder === 'asc') {
        return compareA.localeCompare(compareB, undefined, { numeric: true });
      } else {
        return compareB.localeCompare(compareA, undefined, { numeric: true });
      }
    });
    setDashboardUnitsUpdated(dashboardUnitsSorted);
  }
  
  
  
  useEffect(() => {
    const dashboardUnitsSorted = Object.values(dashboardUnitsUpdated).sort((a, b) => {
      if (dashboardUnitsUpdated !== undefined) {
        const compareA = getProperty(a, sortField)?.toString();
        const compareB = getProperty(b, sortField)?.toString();
  
        if (compareA === null || compareA === undefined) {
          return sortOrder === 'asc' ? 1 : -1;
        }
  
        if (compareB === null || compareB === undefined) {
          return sortOrder === 'asc' ? -1 : 1;
        }
  
        if (sortOrder === 'asc') {
          return compareA.localeCompare(compareB, undefined, { numeric: true });
        } else {
          return compareB.localeCompare(compareA, undefined, { numeric: true });
        }
      }
    });
    setDashboardUnitsUpdated(dashboardUnitsSorted);
  }, [sortField, sortOrder]);

  useEffect(() => {
    const issuesSorted = Object.values(currentIssuesList).sort((a, b) => {
      if (currentIssuesList !== undefined) {
        const compareA = getProperty(a, sortFieldIssue)?.toString();
        const compareB = getProperty(b, sortFieldIssue)?.toString();
  
        if (compareA === null || compareA === undefined) {
          return sortOrderIssue === 'asc' ? 1 : -1;
        }
  
        if (compareB === null || compareB === undefined) {
          return sortOrderIssue === 'asc' ? -1 : 1;
        }
  
        if (sortOrderIssue === 'asc') {
          return compareA.localeCompare(compareB, undefined, { numeric: true });
        } else {
          return compareB.localeCompare(compareA, undefined, { numeric: true });
        }
      }
    });
    setCurrentIssuesList(issuesSorted);
  }, [sortFieldIssue, sortOrderIssue]);

  useEffect(() => {
    if (dashboardUnitsComplete.length !== 0) {
      const updatedData = Object.values(dashboardUnitsComplete).filter((item) => {
        if (unitIdFilter !== '' && !item.unit?.toString().toLowerCase().startsWith(unitIdFilter.toLowerCase())) {
          return false;
        }
        if (organizationFilter && item.organization?.toLowerCase() !== organizationFilter.toLowerCase()) {
          return false;
        }
        if (centerFilter && item.center?.toLowerCase() !== centerFilter.toLowerCase()) {
          return false;
        }
        if (issueTypeInDashboardFilter !== '' && item.status?.toLowerCase() !== issueTypeInDashboardFilter.toLowerCase()) {
          return false;
        }
        return true;
      });
      sortDashboardUnits(updatedData);
    }
  }, [unitIdFilter, organizationFilter, centerFilter, issueTypeInDashboardFilter]);

  useEffect(() => {
    handleCurrentIssuesList(selectedUnit);
  }, [selectedUnit, groupIssues, issueIdFilter, issueTypeFilter, issueStatusSelected, issueIDsSelected]);

  const handleCurrentIssuesList = (unit) => {
    var filteredDataByUnit = [];
    if (lastItemsByUnitAndIssue[unit]) {
      filteredDataByUnit = (groupIssues ? lastItemsByUnitAndIssue[unit] : classifiedIssuesbyUnit[unit]).filter((item) => {
        if (issueIdFilter && !item.Issue.ID.toString().toLowerCase().startsWith(issueIdFilter.toLowerCase())) {
          return false;
        }
        if (issueTypeFilter && item.Issue.type !== issueTypeFilter) {
          return false;
        }
        if (!issueStatusSelected.some(status => status.value === item.Issue.status)) {
          return false;
        }
        if (issueIDsSelected.includes(item.Issue.ID)) {
          return false; // Exclude items with Issue.ID in issueIDsSelected
        }
        return true;
      });
    }
    const issuesSorted = Object.values(filteredDataByUnit).sort((a, b) => {
      if (filteredDataByUnit !== undefined) {
        const compareA = getProperty(a, sortFieldIssue)?.toString();
        const compareB = getProperty(b, sortFieldIssue)?.toString();
  
        if (compareA === null || compareA === undefined) {
          return sortOrderIssue === 'asc' ? 1 : -1;
        }
  
        if (compareB === null || compareB === undefined) {
          return sortOrderIssue === 'asc' ? -1 : 1;
        }
  
        if (sortOrderIssue === 'asc') {
          return compareA.localeCompare(compareB, undefined, { numeric: true });
        } else {
          return compareB.localeCompare(compareA, undefined, { numeric: true });
        }
      }
    });
    setCurrentIssuesList(issuesSorted);
    setIssueIDs(Array.from(new Set(filteredDataByUnit.map(item => item.Issue.ID))));
  }




  const getProperty = (obj, path) => {
    return path.split('.').reduce((acc, key) => acc?.[key], obj);
  };

  const postToSlack = async (message) => {
    try {
      const firebaseFunctionUrl = 'https://us-central1-kamleon-preproduction.cloudfunctions.net/postToSlack';

      // Make a POST request to the Firebase Function
      await axios.post(firebaseFunctionUrl, { message });

      console.log('Message sent to Slack successfully');
    } catch (error) {
      console.error('Error sending message to Slack:', error);
    }
  };

  const generateAndPostSlackMessage = async (issue) => {
    const userDocs = await getDocs(users);
    const usersArray = userDocs.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    const assignedId = issue.Assigned.to;
    const notificationIds = issue.Notification.to;
    //await fetchUsers();
    const mentionedUsers = [
      { name: 'Laia', lastname: 'Alentorn', slackId: 'UND5T3Y21'},
      { name: 'Gonzalo', lastname: 'Roca', slackId: 'UJRCTDWCA' },
      { name: 'Daniel', lastname: 'Vea', slackId: 'U01D92LPAJ1' },
      { name: 'Daniel', lastname: 'de Vargas', slackId: 'U061R65FXFE' },
      { name: 'Arnau', lastname: 'Crespo', slackId: 'U05VB1QBEP9' },
    ];

    // Function to mention users
    const mentionUsers = (users) => {
      return users.map((user) => `<@${user.slackId}>`).join(' ');
    };

    const getUserInfo = async (userId) => {
      return usersArray.find((user) => user.id === userId);
    };

    const generateNotificationString = async (userIds) => {
      const usersInfo = await Promise.all(userIds.map((userId) => getUserInfo(userId)));

      if (usersInfo.length === 1) {
        const user = usersInfo[0];
        const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
        return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
      } else if (usersInfo.length === 2) {
        const mentions = usersInfo.map((user) => {
          const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
          return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
        });
        return mentions.join(' and ');
      } else {
        const lastUser = usersInfo.pop();
        const usersString = usersInfo.map((user) => {
          const mention = mentionedUsers.find((mentionedUser) => mentionedUser.name === user.name && mentionedUser.lastname === user.lastName);
          return mention ? mentionUsers([mention]) : `${user.name} ${user.lastName}`;
        }).join(', ');
        const lastMention = mentionedUsers.find((mentionedUser) => mentionedUser.name === lastUser.name && mentionedUser.lastname === lastUser.lastName);
        const lastUserString = lastMention ? mentionUsers([lastMention]) : `${lastUser.name} ${lastUser.lastName}`;
        return `${usersString}, and ${lastUserString}`;
      }
    };

    const assignedUser = await getUserInfo(assignedId);
    var message = '';
    if (notificationIds.length === 0 && assignedId !== '') {
      message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}. It has been assigned to ${mentionUsers(mentionedUsers.filter(user => user.name === assignedUser.name && user.lastname === assignedUser.lastName))}.`;
    }
    if (assignedId === '' && notificationIds.length > 0) {
      const notificationString = await generateNotificationString(notificationIds);
      message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID} and concerns ${notificationString}.`;
    }
    if (assignedId !== '' && notificationIds.length > 0) {
      const notificationString = await generateNotificationString(notificationIds);
      message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}. It has been assigned to ${mentionUsers(mentionedUsers.filter(user => user.name === assignedUser.name && user.lastname === assignedUser.lastName))} and concerns ${notificationString}.`;
    }
    if (assignedId === '' && notificationIds.length === 0) {
      message = `A ${issue.Installation.Dev_type} ${issue.Issue.type} ${issue.Issue.number} ocurred on unit ${issue.Installation.Unit_ID}.`;
    }
    postToSlack(message);
  }

  // Function to update Assigned.to and Notification.to fields for an array of issues
  const updateAssignedAndNotificationFields = async (issues) => {
    try {
      await Promise.all(
        issues.map(async (issueId) => {
          // Fetch the issue document from "kam_eye" collection
          const issueDoc = await getDoc(doc(db4, 'kam_eye', issueId[0]));
          const issueData = issueDoc.data();

          // Fetch the issue type document from "issue_types" collection
          const issueTypeDoc = await getDoc(doc(db4, 'monitoring_issue_types', issueId[1]));
          const issueTypeData = issueTypeDoc.data();

          // Access the issueType data to get assigned and assignees fields
          const assignedValue = issueTypeData.assigned;
          const assigneesValue = issueTypeData.assignees;

          // Check if timestamps are null and update them if necessary
          const openCreatedAt = issueData.timestamps.open.Created_at;
          const closeCreatedAt = issueData.timestamps.open.Last_open;

          if (!openCreatedAt) {
              issueData.timestamps.open.Created_at = serverTimestamp();
          }

          if (!closeCreatedAt) {
              issueData.timestamps.open.Last_open = serverTimestamp();
          }

          // Update the issue document in "kam_eye" collection only if values are not empty
          if ((assignedValue !== '' && issueData.Assigned.to === '') || (assigneesValue.length > 0 && issueData.Notification.to.length === 0)) {
            const updatedIssue = {
              ...issueData,
              Assigned: {
                ...issueData.Assigned,
                to: assignedValue !== '' ? assignedValue : issueData.Assigned.to,
              },
              Notification: {
                ...issueData.Notification,
                to: assigneesValue.length > 0 ? assigneesValue : issueData.Notification.to,
              }
            };

            await setDoc(doc(db4, 'kam_eye', issueId[0]), updatedIssue);
          }
        })
      );
    } catch (error) {
      console.error('Error updating Assigned and Notification fields:', error);
    }
  };

  // Function to compare kam_eye issues with issue_types
  const compareIssuesWithTypes = async () => {
    try {
      const kamEyeDocs = await getDocs(kam_eye_issues);
      const issueTypesDocs = await getDocs(issue_types);

      const updatedIssues = [];

      await Promise.all(
        kamEyeDocs.docs.map(async (kamEyeDoc) => {
          const kamEyeData = kamEyeDoc.data();

          // Find corresponding issue type in issue_types collection
          issueTypesDocs.docs.find((issueTypeDoc) => {
            // Compare Issue.ID field 
            if (kamEyeData.Issue.ID.toLowerCase().includes(issueTypeDoc.id.toLowerCase())) {
              // Check if Assigned.to should be updated
              if (
                kamEyeData.Assigned &&
                kamEyeData.Assigned.to === ''
              ) {
                updatedIssues.push([kamEyeDoc.id, issueTypeDoc.id]);
              }

              // Check if Notification.to should be updated
              else if (
                kamEyeData.Notification &&
                kamEyeData.Notification.to &&
                kamEyeData.Notification.to.length === 0
              ) {
                updatedIssues.push([kamEyeDoc.id, issueTypeDoc.id]);
              }
            }
          });
        })
      );

      if (updatedIssues.length > 0) {
        await updateAssignedAndNotificationFields(updatedIssues);
      }
    } catch (error) {
      console.error('Error comparing issues with types:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userName !== null) {
          const dashboardData = await fetchDashboardUnits(data);
          const updatedData = Object.values(dashboardData).filter((item) => {
            if (unitIdFilter !== '' && !item.unit?.toString().toLowerCase().startsWith(unitIdFilter.toLowerCase())) {
              return false;
            }
            if (organizationFilter && item.organization?.toLowerCase() !== organizationFilter.toLowerCase()) {
              return false;
            }
            if (centerFilter && item.center?.toLowerCase() !== centerFilter.toLowerCase()) {
              return false;
            }
            if (issueTypeInDashboardFilter !== '' && item.status?.toLowerCase() !== issueTypeInDashboardFilter.toLowerCase()) {
              return false;
            }
            return true;
          });
          setDashboardUnitsComplete(updatedData);
          sortDashboardUnits(updatedData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [dashboardUnitsData, devicesData, unitsData, data, centersData]);
  
  // Fetch devices snapshot

  const handleAddedDevice = (doc) => {
    const device = { id: doc.id, ...doc.data() };
    setDevicesData((prevData) => {
      const newData = [...prevData, device];
      // Add any additional logic if needed, for example:
      // updateDeviceChartsAndCounters(newData);
      return newData;
    });
  };
  
  const handleModifiedDevice = (doc) => {
    const updatedDevice = { id: doc.id, ...doc.data() };
    setDevicesData((prevData) => {
      const updatedDevices = prevData.map((device) =>
        device.id === doc.id ? updatedDevice : device
      );
      // Add any additional logic if needed, for example:
      // updateDeviceChartsAndCounters(updatedDevices);
      return updatedDevices;
    });
  };
  
  const handleRemovedDevice = (doc) => {
    setDevicesData((prevData) => {
      const updatedDevices = prevData.filter((device) => device.id !== doc.id);
      // Add any additional logic if needed, for example:
      // updateDeviceChartsAndCounters(updatedDevices);
      return updatedDevices;
    });
  };

  useEffect(() => {
    if (userName === null) {
      return;
    }

    const unsubscribeFirestoreDevices = onSnapshot(devices, (snapshot) => {
      if (isInitialLoadDevices.current) {
        isInitialLoadDevices.current = false;
        return;
      }

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          handleAddedDevice(change.doc);
        } else if (change.type === "modified") {
          handleModifiedDevice(change.doc);
        } else if (change.type === "removed") {
          handleRemovedDevice(change.doc);
        }
      });
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeFirestoreDevices();
    };
  }, []);

  // Fetch units snapshot

  useEffect(() => {
    dashboardUnitsDataRef.current = dashboardUnitsData;
  }, [dashboardUnitsData]);

  const handleAddedUnit = (doc) => {
    const unit = { id: doc.id, ...doc.data() };
    setUnitsData((prevData) => {
      const newData = [...prevData, unit];
      updateDashboardUnitChartData(newData);
      return newData;
    });
  };

  const handleModifiedUnit = (doc) => {
    const updatedUnit = { id: doc.id, ...doc.data() };
    setUnitsData((prevData) => {
      const updatedUnits = prevData.map((unit) =>
        unit.id === doc.id ? updatedUnit : unit
      );
      updateDashboardUnitChartData(updatedUnits);
      return updatedUnits;
    });
  };

  const handleRemovedUnit = (doc) => {
    setUnitsData((prevData) => {
      const updatedUnits = prevData.filter((unit) => unit.id !== doc.id);
      updateDashboardUnitChartData(updatedUnits);
      return updatedUnits;
    });
  };

  const updateDashboardUnitChartData = (updatedUnits) => {
    const dashboardUnitIds = dashboardUnitsDataRef.current.map(unit => unit.unitId);
    const matchingUnits = updatedUnits.filter(unit => dashboardUnitIds.includes(unit.unitID));
    updateUnitChartData(matchingUnits);
  };

  useEffect(() => {
    if (userName === null) {
      return;
    }

    const unsubscribeFirestoreUnits = onSnapshot(units, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (isInitialLoadUnits.current) {
          isInitialLoadUnits.current = false;
          return;
        }

        if (change.type === "added") {
          handleAddedUnit(change.doc);
        } else if (change.type === "modified") {
          handleModifiedUnit(change.doc);
        } else if (change.type === "removed") {
          handleRemovedUnit(change.doc);
        }
      });
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeFirestoreUnits();
    };
  }, []);

  // Fetch issues snapshot

  useEffect(() => {
    criticalIssuesDataRef.current = criticalIssuesData;
  }, [criticalIssuesData]);

  useEffect(() => {
    if (userName === null) {
      return;
    }

    const unsubscribeFirestoreKamEye = onSnapshot(kam_eye_issues, (snapshot) => {
      if (isInitialLoadIssues.current) {
        isInitialLoadIssues.current = false;
        return;
      }

      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          handleAddedIssue(change.doc);
        } else if (change.type === "modified") {
          handleModifiedIssue(change.doc);
        } else if (change.type === "removed") {
          handleRemovedIssue(change.doc);
        }
      });
    });

    // Cleanup listeners on unmount
    return () => {
      unsubscribeFirestoreKamEye();
    };
  }, []);

  const handleAddedIssue = (doc) => {
    const issue = { id: doc.id, ...doc.data() };
    updateIssueType(issue, criticalIssuesDataRef.current);
    setData((prevData) => {
      const newData = [...prevData, issue];
      updateChartsAndCounters(newData);
      return newData;
    });
  };

  const handleModifiedIssue = (doc) => {
    const updatedIssue = { id: doc.id, ...doc.data() };
    updateIssueType(updatedIssue, criticalIssuesDataRef.current);
    setData((prevData) => {
      const updatedIssues = prevData.map((issue) =>
        issue.id === doc.id ? updatedIssue : issue
      );
      updateChartsAndCounters(updatedIssues);
      return updatedIssues;
    });
  };

  const handleRemovedIssue = (doc) => {
    setData((prevData) => {
      const updatedIssues = prevData.filter((issue) => issue.id !== doc.id);
      updateChartsAndCounters(updatedIssues);
      return updatedIssues;
    });
  };

  const updateIssueType = (issue, criticalIssues) => {
    // Using the criticalIssuesData state to check if the issue is critical
    const issueId = issue.Issue?.ID?.toLowerCase();
    
    if (issueId && criticalIssues.size > 0 && criticalIssues.has(issueId)) {
      issue.Issue.type = "Critical";
    }
  };

  const updateChartsAndCounters = (updatedIssues) => {
    setData(updatedIssues);
    updatePieChartData(updatedIssues);
    updateBarChartData(updatedIssues);

    // Filter data for the last seven days and update state counter data
    const currentDate = new Date();
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    const recentData = updatedIssues.filter((item) => {
      const openTimestamps = item.timestamps?.open;

      if (openTimestamps) {
        const latestTimestamp = Object.values(openTimestamps)
          .map(timestamp => timestamp?.toDate())
          .sort((a, b) => b - a)[0];
        return latestTimestamp && latestTimestamp >= sevenDaysAgo;
      }
      return false;
    });
    updateStateCounterData(recentData);
  };
  

  useEffect(() => {
    const dailyUnitUsageTimeStampsRef = ref(database, '/dailyUnitUsageTimeStamps/');

    const handleDataChange = () => {
      if (userName !== null) {
        fetchDashboardUnits();
      }
    };

    onValue(dailyUnitUsageTimeStampsRef, handleDataChange);

    // Cleanup listeners on unmount
    return () => {
      off(dailyUnitUsageTimeStampsRef, 'value', handleDataChange);
    };
  }, []);

  useEffect(() => {
    const dailyUnitsUsageAveragesRef = ref(database, '/dailyUnitsUsageAverages/');

    const handleDataChange = () => {
      if (userName !== null) {
        fetchDashboardUnits();
      }
    };

    onValue(dailyUnitsUsageAveragesRef, handleDataChange);

    // Cleanup listeners on unmount
    return () => {
      off(dailyUnitsUsageAveragesRef, 'value', handleDataChange);
    };
  }, []);

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await setPersistence(auth4, browserSessionPersistence);

        const unsubscribeAuth = auth4.onAuthStateChanged(async (user) => {
          navigate('/');
          if (user) {
            setUser(user);
            const uid = user.uid;

            const userDocRef = doc(db4, 'users', uid);
            const userDocSnapshot = await getDoc(userDocRef);

            if (userDocSnapshot.exists()) {
              const userData = userDocSnapshot.data();
              if (!(userData.kamleonPermissions.userRole === 'kamleon_admin' || userData.kamleonPermissions.userRole === 'kamleon_editor' || userData.kamleonPermissions.userRole === 'kamleon_viewer' || userData.kamleonPermissions.userRole === 'kamleon_owner')) {
                // User does not have the necessary permissions
                auth4.signOut();
                setShowAuthModal(true);
                setShowApp(false);
                setLoading(false);
                return;
              }
              sessionStorage.setItem('userName', userData.name);
              sessionStorage.setItem('userLastName', userData.lastName);
              sessionStorage.setItem('userEmail', userData.email);
              sessionStorage.setItem('userRole', userData.kamleonPermissions.userRole);
              await fetchData();
              await fetchUsers();
              setShowDashboard(true);
              setShowApp(true);
              setShowAuthModal(false);
              setLoading(false);
            } else {
              console.log('User document not found.');
              setShowAuthModal(true);
              setShowApp(false);
              setLoading(false);
            }
          } else {
            setUser(null);
            setShowAuthModal(true);
            setShowApp(false);
            console.log('No user is currently authenticated.');
            setLoading(false);
          }
        });


        // Cleanup function
        return () => {
          unsubscribeAuth();
        };
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    initializeAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth4);
      sessionStorage.removeItem('userName');
      setUser(null);
      setShowAuthModal(true);
      setShowApp(false);
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  async function deleteDocuments(issueGroup) {
    const batch = writeBatch(db4);
    const cutoffDate = new Date('2024-05-20T00:00:00Z');
    var count = 0;
    for (const issue of issueGroup) {
      const createdAt = issue.timestamps?.open?.Created_at?.toDate();  // Assuming Created_at is a Firestore Timestamp
      if (createdAt && createdAt < cutoffDate) {
        const docRef = doc(db4, 'kam_eye', issue.id);
        await deleteDoc(docRef);
        count++;
        console.log('Document deleted:', issue.id, 'Count:', count);
      }
    }
  }

  const deleteOldDocuments = async () => {
    const kamEyeCollection = collection(db4, 'kam_eye');
    const cutoffDate = new Date('2024-05-20T00:00:00Z');
    try {
      const querySnapshot = await getDocs(kamEyeCollection);
      const batch = writeBatch(db4);  // Correctly initialize the batch
  
      querySnapshot.forEach((documentSnapshot) => {
        const data = documentSnapshot.data();
        const createdAt = data?.timestamps?.open?.Created_at?.toDate();  // Assuming Created_at is a Firestore Timestamp
  
        if (createdAt && createdAt < cutoffDate) {
          batch.delete(documentSnapshot.ref);
        }
      });
  
      await batch.commit();
      console.log('Successfully deleted documents');
    } catch (error) {
      console.error('Error deleting documents:', error);
    }
  };
  

  const handleUnit = (unit_ID, center) => {
    setSelectedUnit(unit_ID);
    setSelectedUnitCenter(center);
      handleCurrentIssuesList(unit_ID);
      setShowDashboard(false);
      setShowIssuesTable(true);
      if (!(unit_ID in dashboardUnits && dashboardUnits[unit_ID].status !== "")) {
        setIssueToModify({Issue: {Installation: {Unit_ID: unit_ID, Center: center}}});
      }
    // Navigate to the issues table route
    navigate(`/issues?unit=${unit_ID}&center=${center}`);
  }

  const backToDashboard = () => {
    setSelectedUnit(null);
    setShowIssuesTable(false);
    setShowDashboard(true);
    navigate('/');
  }

  const handleHandleIssue = (item, issueGroup) => {
    setShowIssuesTable(false);
    setShowHandleIssue(true);
    setIssueToModify(item);
    // deleteDocuments(issueGroup);
    // deleteOldDocuments();
    if (groupIssues && issueGroup.length > 1) {
      setIssueGroupToModify(issueGroup);
    }
    else {
      setIssueGroupToModify({});
    }
    // Navigate to the handle issue route
    navigate(`/handle-issue?id=${item.id}`);
  };

  const handleSignUpClick = () => {
    setShowAuthModal(false);
  };

  const handleMultiSelectChange = (selectedOptions) => {
    setIssueStatusSelected(selectedOptions);
  };

  const handleHideIssuesMultiSelectChange = (selectedOptions) => {
    setIssueIDsSelected(selectedOptions.map(option => (option.value)));
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.hasValue ? '#FC664E' : 'white', // Change background color when value is selected
    }),
  };

  return (
    <div className="App">
      {showApp && (
        <div >
          <div className="row pt-1 pb-2 mx-0" style={{ borderBottom: '1px solid grey', overflowX: 'hidden' }}>
            <div className="col-6 pe-0 ps-3">
              <div className="text-start">
                <KamleonLogo style={{ width: '115px', height: '16px' }} />
              </div>
            </div>
            <div className="col-6 ps-0 pe-3">
              <div className="d-flex align-items-center">
                <div className="ms-auto d-flex align-items-center">
                  <button
                    type="button"
                    className="btn btn-link px-0 me-4"
                    onClick={() => { setShowIssueSettings(!showIssueSettings) }}
                  >
                    <GearIcon style={{ width: '25px', height: '25px' }} />
                  </button>
                  <button
                    type="button"
                    className="btn btn-link px-0"
                    onClick={handleLogout}
                  >
                    <LogOut style={{ width: '24px', height: '24px' }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        <div className="container">
          {showMqttModal && (
            <MqttComponent place="unit" issue={{Installation: {Unit_ID: selectedUnit, Center: selectedUnitCenter}}} onShow={showMqttModal} onClose={() => { setShowMqttModal(false) }} userRole={userRole} />
          )}
          <Routes>
            <Route path="/" element={
              <div className='py-4'>
                <div className="text-start mb-3">
                  <h5><strong>MONITORING</strong></h5>
                </div>
                <div className="row d-flex align-items-stretch pb-4">
                  <div className="col-4">
                    <StateCounter inputData={stateCounteData} />
                  </div>
                  <div className="col-4">
                    <PieChart inputData={pieChartData} />
                  </div>
                  <div className="col-4">
                    <UnitActivityChart inputData={unitChart} />
                  </div>
                </div>
                <div className="row g-3 pb-4">
                  <div className="col-3 me-2">
                    <select
                      className='form-select'
                      value={organizationFilter}
                      onChange={(e) => setOrganizationFilter(e.target.value)}
                    >
                      <option value="">All Organizations</option>
                      {Array.from(new Set(Object.values(dashboardUnitsComplete).map(item => item.organization))).map((organization, index) => (
                        <option key={index} value={organization}>{organization}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 ms-0 p-0 me-2">
                    <select
                      className='form-select'
                      value={centerFilter}
                      onChange={(e) => setCenterFilter(e.target.value)}
                    >
                      <option value="">All Centers</option>
                      {Array.from(new Set(Object.values(dashboardUnitsComplete).map(item => item.center))).map((center, index) => (
                        <option key={index} value={center}>{center}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-3 ms-0">
                    <select
                      className='form-select'
                      value={issueTypeInDashboardFilter}
                      onChange={(e) => {
                        setIssueTypeInDashboardFilter(e.target.value);
                      }}
                    >
                      <option value="">All Issue Types</option>
                      <option value="BackEnd">Backend</option>
                      <option value="Critical">Critical</option>
                      <option value="Error">Error</option>
                      <option value="Warning">Warning</option>
                      <option value="Exception">Exception</option>
                    </select>
                  </div>
                  <div className="col-2 m-0 mt-3 ms-auto form-search">
                    <div className="input-container ms-0">
                      <span className="icon"><FontAwesomeIcon icon={faMagnifyingGlass} style={{ color: '#212529' }}/></span>
                      <input
                        className='form-control dark-placeholder-color'
                        type="text"
                        placeholder="Search by unit"
                        style={{ borderRadius: '21px' }}
                        value={unitIdFilter}
                        onChange={(e) => {
                          setUnitIdFilter(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <table className="table table-striped table-hover bdr mb-5">
                  <thead className="table-dark rounded-top">
                    <tr>
                      <th onClick={() => handleSort('status')}>
                        STATUS
                        {/* <span className={`arrow ${sortField === 'Issue.type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('organization')}>
                        ORG
                        {/* <span className={`arrow ${sortField === 'Issue.ID' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('center')}>
                        CENTER
                        {/* <span className={`arrow ${sortField === 'timestamps.open.Created_at' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('unit')}>
                        UNIT
                        {/* <span className={`arrow ${sortField === 'timestamps.open.Created_at' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('time_installed')}>
                        TIME INSTALLED SENSOR
                        {/* <span className={`arrow ${sortField === 'Installation.Center' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('since_last_score')}>
                        SINCE LAST SCORE
                        {/* <span className={`arrow ${sortField === 'Installation.Dev_type' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('daily_unit_use')}>
                        DAILY UNIT USE
                        {/* <span className={`arrow ${sortField === 'Installation.Unit_ID' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('total_kpod_use')}>
                        TOTAL K-POD USE
                        {/* <span className={`arrow ${sortField === 'Issue.status' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('temperature')}>
                        Tº (ºC)
                        {/* <span className={`arrow ${sortField === 'Issue.status' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                      <th onClick={() => handleSort('humidity')}>
                        HUMIDITY (%)
                        {/* <span className={`arrow ${sortField === 'Issue.status' && sortOrder === 'desc' ? 'down' : 'up'}`}>&#9650;</span> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(dashboardUnitsUpdated).map((item) => {
                      return (
                        <tr
                          key={item.unit}
                          onClick={() => handleUnit(item.unit, item.center)}
                          style={{ cursor: 'pointer' }}
                          className={item.usage_status === 'exceeded' ? 'alert-row' : ''}
                        >
                          <td className='pt-2' style={{ display: 'flex', alignItems: 'center' }}>
                            <span
                              style={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '50%',
                                backgroundColor: item.unit_status === 'active' ? '#75CC6E' : '#FC664E',
                                marginRight: '8px',
                                marginTop: '20px',
                                marginBottom: '20px'
                              }}
                            ></span>
                            {((item.status === 'Critical' || item.status === 'BackEnd') && groupedDataByUnit[item.unit]?.Issue?.status !== "Closed") && (
                              <Critical style={{ width: '32px', height: '32px' }} />
                            )}
                            {(item.status === 'Error' && groupedDataByUnit[item.unit].Issue.status !== "Closed") && (
                              <Error style={{ width: '32px', height: '32px' }} />
                            )}
                            {(item.status === 'Warning' && groupedDataByUnit[item.unit].Issue.status !== "Closed") && (
                              <Warning className='mt-2' style={{ width: '32px', height: '32px' }} />
                            )}
                            {(item.status === 'Exception' && groupedDataByUnit[item.unit].Issue.status !== "Closed") && (
                              <Exception style={{ width: '32px', height: '32px' }} />
                            )}
                            {item.status !== 'BackEnd' && item.status !== 'Critical' && item.status !== 'Error' && item.status !== 'Warning' && item.status !== 'Exception' && (
                              item.status
                            )}
                          </td>
                          <td>{item.organization}</td>
                          <td>{item.center}</td>
                          <td>{item.unit}</td>
                          <td>{item.time_installed ? (`${formatTimestamp(item.time_installed).split(' ')[0]}`) : ("-")}</td>
                          <td>{formatSinceLastScore(item.since_last_score)}</td>
                          <td>{item.daily_unit_use}</td>
                          <td>{item.total_kpod_use}</td>
                          <td>{item.temperature === "" ? "-" : parseFloat(item.temperature).toFixed(1)}</td>
                          <td>{item.humidity === "" ? "-" : parseFloat(item.humidity).toFixed(2)}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            }/>
            <Route path="/issues" element={
              <IssuesTable
                currentIssuesList={currentIssuesList}
                groupIssues={groupIssues}
                handleSortIssue={handleSortIssue}
                handleHandleIssue={handleHandleIssue}
                handleMultiSelectChange={handleMultiSelectChange}
                handleHideIssuesMultiSelectChange={handleHideIssuesMultiSelectChange}
                setGroupIssues={setGroupIssues}
                issueStatus={issueStatus}
                issueIDs={issueIDs}
                customStyles={customStyles}
                issueStatusSelected={issueStatusSelected}
                issueTypeFilter={issueTypeFilter}
                setIssueTypeFilter={setIssueTypeFilter}
                issueIdFilter={issueIdFilter}
                setIssueIdFilter={setIssueIdFilter}
                formatTimestamp={formatTimestamp}
                classifiedIssuesbyUnit={classifiedIssuesbyUnit}
                selectedUnit={selectedUnit}
                selectedUnitCenter={selectedUnitCenter}
                classifiedIssues={classifiedIssues}
                usersData={usersData}
                userName={userName}
                userLastName={userLastName}
                showHandleIssue={showHandleIssue}
                setShowHandleIssue={setShowHandleIssue}
                issueToModify={issueToModify}
                issueGroupToModify={issueGroupToModify}
                backToDashboard={backToDashboard}
                setShowMqttModal={setShowMqttModal}
                classifiedIssuesbyUnitAndKey={classifiedIssuesbyUnitAndKey}
                userRole={userRole}
              />
            } />
            <Route path="/handle-issue" element={                     
              <HandleIssue
                issue={issueToModify}
                issueGroup={issueGroupToModify}
                usersData={usersData}
                userName={userName}
                userLastName={userLastName}
                onShow={() => {
                  setShowHandleIssue(true);
                }}
                onClose={() => {
                  setShowHandleIssue(false);
                  setShowIssuesTable(true);
                  navigate(`/issues?unit=${issueToModify.Installation.Unit_ID}&center=${issueToModify.Installation.Center}`);
                }}
                userRole={userRole}
              />
            } />
          </Routes>
          <div className="text-center mt-3 text-muted"><i>Version 1.3.0</i></div>
        </div>
      </div>
      )}
      {showAuthModal && (
        <Auth
          onShow={showAuthModal}
          onClose={() => {
            setShowAuthModal(false)
            setShowApp(true)
          }}
          user={user}
          setUser={setUser}
          // onSignUpClick={handleSignUpClick}
          showAuthModal={showAuthModal}
          loading={loading}
          setLoading={setLoading}
          email={email}
        />
      )}
      {showMqttComponent && (
        <MqttComponent place="issue" issue={issueToModify} onShow={showMqttComponent} onClose={() => { setShowMqttComponent(false) }} userRole={userRole}/>
      )}
      {showIssueSettings && (
        <IssueSettings usersData={usersData} onShow={showIssueSettings} onClose={() => { setShowIssueSettings(false) }} />
      )}

    </div>
  );
}

export default App;